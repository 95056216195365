import React from 'react';
import './About.css'

function About() {
  return (
    <div style={{ height: '80%', padding: '1em', overflow: 'scroll'}}>
      <h2>👋🏻 I'm Jacob</h2>
      <p>Not affiliated with TMG, just a mid-level software engineer who's a big fan of their content. <br/>Was part of the recent GREAT TECH LAYOFFS so I've been building cool projects while applying for jobs.</p><br/><hr/><br/>

      <h2>🐞 Website Bugs/Features</h2>
      <p>For feature requests or bug fixes, email <b>search.tmg.pod+improve@gmail.com</b></p><br/><hr/><br/>

      <h2>🛸 To the TMG team 🛸</h2>
      <p>If you're Cody, Noel, or anyone else from the TMG Studios team, hit me up at <b>search.tmg.pod+fromtmg@gmail.com</b><br/>I've got a proposal regarding <a href="https://youtu.be/j-P0g8PTVGM?t=782">this segment of this episode</a></p><br/><hr/><br/>

      <h2>👨🏻 Hire Jacob 👨🏻</h2>
      <p>My background is as a back-end engineer, <br></br>working with microservice architecture in Java, Kotlin, and Python. <br></br>If you're looking for an engineer, let me know at <b>search.tmg.pod+hirejacob@gmail.com</b></p>
    </div>
  );
}

export default About;