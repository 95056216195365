import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <Link to="/" className='home-link'>SearchTMG</Link>
      </div>
      <div className="navbar-under">
      I have partnered with TMGStudios to bring you the <a href="https://tmgstudios.tv/pages/search" className='home-link-under'>Official TMG Transcript Search. </a> <br /> The official search has more features like searching all TMGStudios shows, including bonus eps! <br />
      </div>
      <Footer />
    </>
  );
}

export default Navbar;