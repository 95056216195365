import React from 'react';
import Search from './components/Search';
import './App.css';
import Navbar from './components/Navbar';
import About from './components/About';
import Results from './components/Results';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Navbar />
        
        <Routes>
          <Route exact path='/' element={<Search/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/results' element={<Results/>} />
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
