import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p style={{fontWeight: 'bolder', fontSize: '12px'}}>Made by <a href="/about">Jacob</a> 🧔🏻‍♂️ • <a href="https://www.buymeacoffee.com/northernknD">Buy me a coffee</a></p>
    </div>
  );
}

export default Footer;
