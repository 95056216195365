import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/Launch';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useLocation } from 'react-router-dom';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function Results() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { results: initialResults, searchTerm, total, dateStart, dateEnd } = location.state;
  const [results, setResults] = useState(initialResults);
  const [newResults, setNewResults] = useState([]);
  
  const [startDate, setStartDate] = useState(dateStart);
  const [endDate, setEndDate] = useState(dateEnd == "" ? moment().format('YYYY-MM-DD') : dateEnd);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const options = ['TMG', 'Coming soon'];
  const [selectedValue, setSelectedValue] = useState(options[0]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  if (!results || results.length === 0) {
    return (
      <div>
        <h2>No results found</h2>
        <p>{`Try rephrasing or thinking as if you are the Youtube caption auto-generator`}</p>
        <p>{`(Bencel => Ben cells, etc)`}</p>
        <p>Work is in progress to do this type of matching automatically</p>
      </div>
    );
  }

  let cardMaxWidth = '400px';
  if (results.length === 1) {
    cardMaxWidth = '100%';
  } else if (results.length === 2) {
    cardMaxWidth = '50%';
  }

  async function fetchMoreResults() {
    console.log('Fetch more');
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://akzozrjcqh.execute-api.us-east-1.amazonaws.com/opensearch-api-test/?q=${searchTerm}&from=${results.length}&size=10&startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();
      // new results to be appended: data.hits.hits
      setNewResults(data.hits.hits);
      setResults([...results, ...data.hits.hits]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function filterResults() {
    try {
      console.log("here is where we would re-search with query: " + searchTerm + ", startDate: " + startDate + ", end: " + endDate + ", show: " + selectedValue)
      console.log("and then push back to /results")
      setIsLoading(true);
      const response = await fetch(
        `https://akzozrjcqh.execute-api.us-east-1.amazonaws.com/opensearch-api-test/?q=${searchTerm}&from=0&size=10&startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();
      console.log("results: " + data)
      navigate("/results", {
        state: { results: data.hits.hits, searchTerm: searchTerm, total: data.hits.total.value, dateStart: startDate, dateEnd: endDate },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      // Reload the window to capture the new results
      window.location.reload(false);
    }
  }

  return (
    <div style={{ height: '90%', overflow: 'scroll', paddingTop: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px', paddingTop: '33px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '33px' }}>
          <a href="/" style={{ marginLeft: '16px', textDecoration: 'none', paddingRight: '10px', color: "#000" }}><Typography variant="h6" component="h2">⬅ Search</Typography></a>
          <Typography variant="h6" component="h2" style={{ paddingRight: '10px' }}> | </Typography>
          <Typography variant="h6" component="h2"><b>{total} results</b></Typography>
        </div>
        <div style={{ display: 'flex', paddingTop: '33px', paddingRight: '20px' }}>
          <Button style={{ color: '#000', boxShadow: '2px 2px 6px #ccc' }} onClick={handleOpen}>Filter</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Filter Results
              </Typography>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start'}}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Custom Date Range
              </Typography>
              <div style={{ display: 'flex', paddingTop: '10px', justifyContent: 'end', paddingRight: '20px' }}>
                <form noValidate>
                  <TextField
                    id="date"
                    label="Start"
                    type="date"
                    defaultValue={startDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </form>
              </div>
              <div style={{ display: 'flex', paddingTop: '10px', justifyContent: 'end', paddingRight: '20px' }}>
                <form noValidate>
                  <TextField
                    id="date"
                    label="End"
                    type="date"
                    defaultValue={endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </form>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Content
              </Typography>
              <FormControl style={{ paddingRight: '10px' }}>
                <Select
                  value={selectedValue ? selectedValue : "TMG"}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  label="Select an option"
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                <button
                  className='search-button'
                  onClick={filterResults}
                  style={{
                    padding: "10px",
                    margin: "10px",
                    backgroundColor: "#6bd569",
                    height: "70%",
                    width: '100%',
                    color: "#000",
                    fontWeight: "bolder",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "2px 2px 3px #ccc",
                  }}
                >
                  Filter
                </button>
              </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

      <Box sx={{ padding: '24px' }}>
        <Grid container spacing={1}>
          {results.map((hit) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${hit._source.episode_number}${Math.floor(Math.random() * (1000 - 1 + 1) + 1)}`}>
              <Card sx={{
                maxWidth: cardMaxWidth
              }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={hit._source.thumbnail_url}
                  alt={hit._source.episode_name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {hit._source.episode_name}
                  </Typography>
                  {hit.inner_hits.transcripts.hits.hits.map((transcriptHit, index) => (
                    <React.Fragment key={index}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                        <Typography variant="body2" color="text.secondary">
                          {transcriptHit.highlight['transcripts.text'][0].split(/<\/?em>/).map((text, i) => {
                            if (text === '') {
                              return null;
                            } else if (i % 2 === 0) {
                              return text;
                            } else {
                              return (
                                <span style={{ backgroundColor: '#D8F8D8' }} key={i}>
                                  {text}
                                </span>
                              );
                            }
                          })}
                        </Typography>
                        <IconButton
                          href={`${hit._source.episode_link}?t=${transcriptHit._source.timestamp}`}
                          target="_blank"
                          size="small"
                          style={{ marginLeft: 'auto' }}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </div>
                      {index !== hit.inner_hits.transcripts.hits.hits.length - 1 && <hr style={{ borderColor: '#ddd', borderWidth: '0.5px 0 0 0' }} />}
                    </React.Fragment>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {total <= results.length ? <></> :
          <button
            className='search-button'
            onClick={fetchMoreResults}
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "#6bd569",
              height: "70%",
              width: '300px',
              color: "#000",
              fontWeight: "bolder",
              borderRadius: "10px",
              border: "none",
              boxShadow: "2px 2px 3px #ccc",
            }}
          >
            Load More
          </button>}
      </Box>
    </div>
  );
}

export default Results;
